<template>
  <v-navigation-drawer v-model="drawer" temporary app fixed class="elevation-1">
    <v-list flat>
      <div class="ma-4 text-center">
        <img
          width="100"
          height="50"
          :src="require('@/assets/logo-indac-alt-filled.png')"
        />
        <div style="font-weight: bold !important">
          {{ displayAsociado }}
        </div>
        <br />
        <v-divider></v-divider>
      </div>
      <br />
      <v-list-item-group color="primary">
        <v-list-item to="/">
          <v-list-item-title>Inicio</v-list-item-title>
        </v-list-item>

        <template v-for="item in accesos">
          <v-list-item :key="item.key" @click="go(item)" v-if="check(item, 2)">
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item>
        </template>
        <br />
        <br />
        <v-list-item to="/logout">
          <v-list-item-title
            ><v-icon left>mdi-logout-variant</v-icon> Cerrar sesión
          </v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <template v-slot:append>
      <div class="pa-2">
        <v-chip label small>Versión {{ $store.getters.version }}</v-chip>
      </div>
    </template>
  </v-navigation-drawer>
</template>
<script>
export default {
  name: "App",
  data: () => ({}),
  computed: {
    currentUser() {
      return this.$store.getters.user;
    },
    accesos() {
      return this.$store.getters.user.accesos.length > 0
        ? this.$store.getters.user.accesos.filter(
            (c) => (c.canal == this.canal || c.canal == "general") && c.parentKey === null
          )
        : [];
    },
    drawer: {
      get() {
        return this.$store.getters.leftdrawer;
      },
      set(value) {
        this.$store.commit("setLeftDrawer", value);
      },
    },
    displayAsociado() {
      if (this.currentUser && this.currentUser.nombre) {
        return this.currentUser.nombre;
      } else {
        return "NO SELECCIONADO!";
      }
    },
    canal() {
      return this.$store.getters.canal;
    },
  },
  methods: {
    check(item, value) {
     
      return (
        item.type === value &&
        (item.parentKey === null || item.parentKey === "")
      );
    },
    getSubItem(list, property, value) {
      return list === undefined
        ? []
        : list.filter((c) => c[property] === value);
    },
    go(item) {
      this.$store.commit("setParentKey", item.key);
      this.$router.push({ path: item.path });
    },
  },
  props: {
    source: String,
  },
};
</script>
